<template>
  <div class="login">
    <div class="account">
      <div class="right"></div>
      <div class="left">
        <!-- <h1 class="logo">ushareyun 优享云</h1> -->
        <h3>后台登录系统</h3>
        <div class="text">欢迎回来,请输入用户名密码登录</div>
        <div class="userInfo">
          <el-form :rules="rules" :model="form" ref="userInfo">
            <el-row>
              <el-form-item prop="username">
                <el-input placeholder="用户名" v-model="form.username"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="password">
                <el-input placeholder="密码" v-model="form.password" type="password" show-password></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="18">
                <el-form-item prop="checkCode">
                  <el-input placeholder="验证码" v-model="form.checkCode" @keyup.enter.native="login"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" style="height: 40px; position: relative">
                <img :src="codeImg" class="codeImg" @click="getCodeImg" />
              </el-col>
            </el-row>
          </el-form>
          <el-button type="primary" size="medium" style="width: 100%" @click="login">登录</el-button>
          <div class="tiny">
            <div><input type="checkbox" v-model="isKeepPassword" />记住密码</div>
            <div style="color: #28abe3" v-if="false">忘记密码?</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseURL } from '@/utils/request'
export default {
  name: 'login',
  data() {
    return {
      form: {
        username: localStorage.getItem('username') || '',
        password: localStorage.getItem('password') || '',
        checkCode: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      isKeepPassword: localStorage.getItem('username') ? true : false
    }
  },
  components: {},
  methods: {
    login() {
      this.$refs.userInfo.validate(async (v) => {
        //判断账号密码是否填写
        if (v) {
          //判断是不是保存密码
          if (this.isKeepPassword) {
            localStorage.setItem('username', this.form.username)
            localStorage.setItem('password', this.form.password)
          } else {
            localStorage.setItem('username', '')
            localStorage.setItem('password', '')
          }
          this.$store.dispatch('login/toLogin', this.form)
        }
      })
    },
    getCodeImg() {
      this.$store.commit('login/changeCodeImg')
    }
  },
  mounted() {
    this.getCodeImg()
  },
  computed: {
    codeImg() {
      return this.$store.state.login.codeImg
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url('~@/assets/login_bg.png');
  background-size: 100% 100%;
  .account {
    width: 1000px;
    height: 500px;
    background-color: peru;
    margin: 7% auto 0;
    display: flex;
    background-color: white;
    .left {
      flex: 1;
      background-color: white;
      h1 {
        color: #093156;
        padding-left: 20px;
      }
      h3 {
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        color: #343434;
      }
      .text {
        text-align: center;
        color: #999;
        margin-bottom: 40px;
      }
      .userInfo {
        width: 400px;
        margin: 0 auto;
        .codeImg {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
      .tiny {
        margin-top: 20px;
        height: 20px;
        // background-color: pink;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        input {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          margin-bottom: 2px;
          margin-right: 3px;
        }
      }
    }
    .right {
      flex: 1;
      background-image: url('~@/assets/login_right.png');
      background-size: 100% 100%;
    }
  }
}
</style>
